import { useEffect, useState, FunctionComponent } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FORMS_FIELD_TYPE } from "@type/form/field.types";
import { UserDetailsResponseDto } from "@state/users/dto/response/user.details.response.dto";
import {
  requestCreateUser,
  requestGetUser,
  requestUpdateUser,
  requestUpdateUserStatus,
} from "@state/users/UserEffects";
import { PROFILES } from "@utils/enums/profile.enum";
import { ROUTES } from "@routes/Routes";
import { toastError, toastSuccess } from "@utils/toast-helper";
import BaseForm from "@components/forms/BaseForm";

export interface Props {
  userId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const UserForm: FunctionComponent<Props> = (props: Props) => {
  const { userId, editMode, setEditMode } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [user, setUser] = useState<UserDetailsResponseDto | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const FORM = {
    main: {
      key: "main",
      module: "users.edit",
      title: "form.title",
      fields: [
        [
          {
            name: "firstName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            maxLength: 50,
          },
          {
            name: "lastName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            maxLength: 100,
          },
        ],
        [
          {
            name: "email",
            type: FORMS_FIELD_TYPE.TEXT,
            required: true,
            maxLength: 255,
            pattern:
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
          },
        ],
        [
          {
            name: "profile",
            type: FORMS_FIELD_TYPE.SELECT,
            required: true,
            options: PROFILES,
          },
        ],
      ],
    },
  };

  useEffect(() => {
    if (userId) {
      void requestGetUser(userId);
    }
  }, [userId]);

  useEffect(() => {
    return requestCreateUser.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        toastSuccess(t<string>("users.add.messages.success"));
        navigate(ROUTES.admin.users.details.generate(result.data.id));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateUser.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        setUser(result.data);
        toastSuccess(t<string>("users.edit.messages.success"));
        navigate(ROUTES.admin.users.list.generate());
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateUserStatus.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data && user) {
        toastSuccess(t<string>("editUser.status"));
        const newUser = {
          ...user,
          status: result.data.status,
        };

        setUser(newUser);
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  return (
    <>
      <BaseForm
        module="users.edit"
        form={form}
        entityId={userId}
        loading={loading}
        editMode={editMode}
        setEditMode={setEditMode}
        getEntityConfiguration={{
          requestGet: requestGetUser,
        }}
        saveEntityConfiguration={{
          requestCreate: requestCreateUser,
          requestUpdate: requestUpdateUser,
        }}
        formConfiguration={FORM}
        backRoute={ROUTES.admin.users.list.generate()}
      />
    </>
  );
};
export default UserForm;
