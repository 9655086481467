import {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import FormItemData from "@components/forms/FormItemData";
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Modal,
  Popconfirm,
  Spin,
} from "antd";
import { formItemBlockLayout } from "@utils/Constant";
import { Field, OptionType } from "@type/form/field.types";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import {
  ArrowLeftOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import BasicButton from "@components/buttons/BasicButton";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { Effect } from "effector";
import { useNavigate } from "react-router-dom";
import { formUtils } from "@utils/form-utils";
import { toastError } from "@utils/toast-helper";

interface Props {
  title?: string;
  module?: string;
  entityId?: string;
  editMode?: boolean;
  showActions?: boolean;
  setEditMode?: (editMode: boolean) => void;
  fields?: Field[];
  form: FormInstance;
  loading?: boolean;
  getEntityConfiguration?: GetEntityConfiguration;
  saveEntityConfiguration?: SaveEntityConfiguration;
  customOnSubmitCallback?: (values: Store) => void;
  customButtons?: ReactNode[];
  formConfiguration: {
    [key in string]: FormItem;
  };
  fieldsToUpdate?: string[];
  fieldsToSave?: string[];
  backRoute?: string;
}

interface GetEntityConfiguration {
  requestGet: Effect<string, ParsedResponse<any>>;
}

interface SaveEntityConfiguration {
  confirmationContent?: string;
  requestCreate?: Effect<{ dto: any }, ParsedResponse<any>>;
  requestUpdate?: Effect<{ id: string; dto: any }, ParsedResponse<any>>;
}

interface FormContentItem {
  key: string;
  title?: string;
  component: JSX.Element;
}

export interface FormItem {
  key: string;
  title: string;
  fields: FormItemField[][];
  module?: string;
}

export interface FormItemField {
  name: string;
  type: string;
  size?: number;
  required?: boolean;
  maxLength?: number;
  pattern?: RegExp;
  step?: number;
  readOnly?: boolean;
  options?: OptionType[];
  custom?: ReactElement;
}

const { confirm } = Modal;

const BaseForm: FunctionComponent<Props> = (props: Props) => {
  const {
    entityId,
    editMode,
    showActions = true,
    setEditMode,
    customButtons,
    fieldsToSave,
    fieldsToUpdate,
    backRoute,
  } = props;
  const { t } = useTranslation();

  const [fields, setFields] = useState<Field[]>([]);

  const navigate = useNavigate();

  const [entity, setEntity] = useState<unknown>();

  const [getResponseReceived, setGetResponseReceived] =
    useState<boolean>(false);

  const getFormContent = (): FormContentItem[] => {
    const content: FormContentItem[] = [];

    for (const [key, formItem] of Object.entries(props.formConfiguration)) {
      content.push({
        key,
        title: t<string>(formItem.title),
        component: <FormItemData item={formItem} editMode={editMode} />,
      });
    }

    return content;
  };

  const confirmationPopup = async (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t<string>(
          `${props.module ? props.module : "default"}.modals.save.title`,
        ),
        content: props.saveEntityConfiguration?.confirmationContent
          ? props.saveEntityConfiguration?.confirmationContent
          : t<string>(
              `${props.module ? props.module : "default"}.modals.save.content`,
            ),
        okText: t<string>("buttons.yes"),
        cancelText: t<string>("buttons.no"),
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const mapDtoFrom = (values: Store, fields: string[] | undefined): unknown => {
    // Si le dto ne contient que certain champs
    if (fields) {
      const data: Store = {};
      for (const field of fields) {
        data[field] = values[field] as unknown;
      }
      return {
        ...data,
      };
    }
    return {
      ...values,
    };
  };

  const handleSubmit = (values: Store): void => {
    void confirmationPopup().then((confirmed: boolean) => {
      if (confirmed && props.saveEntityConfiguration) {
        // setButtonLoading(true);
        if (props.entityId) {
          if (props.saveEntityConfiguration.requestUpdate) {
            const dtoToUpdate = mapDtoFrom(values, fieldsToUpdate);
            void props.saveEntityConfiguration.requestUpdate({
              id: props.entityId,
              dto: dtoToUpdate,
            });
          }
        } else {
          if (props.saveEntityConfiguration.requestCreate) {
            const dtoToSave = mapDtoFrom(values, fieldsToSave);
            void props.saveEntityConfiguration.requestCreate({
              dto: dtoToSave,
            });
          }
        }
      }
      if (props.customOnSubmitCallback) {
        props.customOnSubmitCallback(values);
      }
    });
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    toastError(t<string>("forms.errors.failed-validation"));
    props.form.scrollToField(errorFields[0].name);
  };

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    return props.getEntityConfiguration?.requestGet.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        setEntity(result.data);
        setFields(formUtils.mapFieldsFrom(result.data));
      } else {
        toastError(t<string>("common.notFound"));
      }
    });
  });

  return (
    <>
      {!getResponseReceived && entityId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !entityId || entity ? (
        <Form
          {...formItemBlockLayout}
          onFinish={handleSubmit}
          form={props.form}
          fields={fields}
          onFinishFailed={onFinishFailed}
        >
          {showActions && (
            <div className="form-first-item-sticky-top-container">
              <div className="form-first-item-sticky-top-content">
                <div className="d-flex align-items-center justify-content-between flex-wrap px-3">
                  <div className="my-3">
                    {editMode && setEditMode ? (
                      <>
                        <Popconfirm
                          title={t<string>(
                            "forms.actions.modals.cancel.content",
                          )}
                          okText={t<string>("buttons.yes")}
                          cancelText={t<string>("buttons.no")}
                          onConfirm={() => {
                            setEditMode(false);
                            entity &&
                              setFields(formUtils.mapFieldsFrom(entity as any));
                          }}
                          placement="top"
                          icon={<QuestionCircleOutlined />}
                          className="m-2"
                        >
                          <Button htmlType="reset">
                            <StopOutlined /> {t<string>("buttons.cancel")}
                          </Button>
                        </Popconfirm>
                      </>
                    ) : (
                      <>
                        <div className="d-flex align-items-center justify-content-center flex-wrap">
                          <Button
                            htmlType="reset"
                            className="m-2"
                            onClick={() =>
                              backRoute ? navigate(backRoute) : navigate(-1)
                            }
                          >
                            <ArrowLeftOutlined /> {t<string>("buttons.back")}
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="my-3">
                    {editMode ? (
                      <BasicButton
                        className="btn-primary m-2"
                        type="submit"
                        text={t<string>("buttons.save")}
                        isLoading={props.loading}
                        icon={<SaveOutlined />}
                      />
                    ) : entity ? (
                      <div className="d-flex align-items-center justify-content-center flex-wrap">
                        {setEditMode && (
                          <BasicButton
                            className="btn-primary m-2"
                            onClick={() => setEditMode(true)}
                            text={t<string>("buttons.edit")}
                            icon={<EditOutlined />}
                          />
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {customButtons && customButtons.length >= 1 && (
                    <div className="my-3">
                      {customButtons.map((button) => button)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {props.title && (
            <Divider orientation="left">
              <h3 className="text-secondary mb-0">{t<string>(props.title)}</h3>
            </Divider>
          )}
          {getFormContent().map((content) => content.component)}
        </Form>
      ) : (
        <div>{t<string>("common.entityNotFound")}</div>
      )}
    </>
  );
};

export default BaseForm;
