import { FC, useCallback, useEffect, useState } from "react";
import { Col, Divider, Empty, Form, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Pageable } from "@type/pagination/pagination.types";
import PageLayout from "@components/layouts/PageLayout";
import { SpinIcon } from "@components/icons/SpinIcon";
import { columns } from "@views/organisationProfessional/list/OrganisationProfessionalsColumns";
import { emptySearchResult } from "@utils/Constant";
import InputFormField from "@components/inputs/InputFormField";
import { useUnit } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import {
  setFilterPage,
  setTablePageNumber,
  setTablePageSize,
} from "@state/auth/AuthEvents";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@routes/Routes";
import { requestGetOrganisationProfessionalsList } from "@state/organisationProfessional/OrganisationProfessionalEffets";
import { OrganisationProfessionalItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.item.response.dto";
import { OrganisationProfessionalSearchCriteria } from "@state/organisationProfessional/dto/organisation.professional.search.criteria";

interface FilterObject {
  query: string;
}

const OrganisationProfessionalsScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useUnit(authentifiedStore);

  const [pageNumber, setPageNumber] = useState<number>(
    authentifiedContext?.dashboardsPageNumber?.["organisationprofessionals"] ||
      1,
  );
  const [pageSize, setPageSize] = useState<number>(
    authentifiedContext?.dashboardsPageSizes?.["organisationprofessionals"] ||
      10,
  );

  const contextFilter: FilterObject | undefined = authentifiedContext
    ?.dashboardsFilter?.["organisationprofessionals"] as
    | FilterObject
    | undefined;

  const [query, setQuery] = useState<string>(contextFilter?.query || "");

  const InitialValues = {
    query: query,
  };

  const onChangeQuery = (current: string): void => {
    setQuery(current);
    setFilterPage({
      value: {
        query: current,
      },
      property: "organisationprofessionals",
    });
    onChangePageNumber(1);
  };

  const [organisationProfessionals, setOrganisationProfessionals] =
    useState<Pageable<OrganisationProfessionalItemResponseDto>>(
      emptySearchResult,
    );

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const onChangePageSize = (current: number, newPageSize: number): void => {
    setPageSize(newPageSize);
    setTablePageSize({
      value: newPageSize,
      property: "organisationprofessionals",
    });
  };

  const onChangePageNumber = (current: number): void => {
    setPageNumber(current);
    setTablePageNumber({
      value: current,
      property: "organisationprofessionals",
    });
  };

  const fetchOrganisationProfessionals = useCallback(() => {
    setDataHasLoaded(false);
    const searchCriteria: OrganisationProfessionalSearchCriteria = {
      query,
    };
    requestGetOrganisationProfessionalsList({
      dto: searchCriteria,
      page: pageNumber - 1,
      limit: pageSize,
    })
      .catch(() => {
        toastError(t<string>("organisationProfessional.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [query, pageNumber, pageSize, t]);

  useEffect(() => {
    fetchOrganisationProfessionals();
  }, [fetchOrganisationProfessionals]);

  useEffect(() => {
    return requestGetOrganisationProfessionalsList.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setOrganisationProfessionals(result.data);
      }
    });
  });

  return (
    <PageLayout title={t<string>("organisationProfessional.title")}>
      <>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("organisationProfessional.listTitle")}
              </h3>
            </Divider>
          </div>
        </div>
        <Col xs={24} lg={12}>
          <Form initialValues={InitialValues}>
            <InputFormField
              module="organisationProfessional.search"
              field="query"
              onPressEnter={(e) => {
                e.preventDefault();
                onChangeQuery(e.currentTarget.value);
              }}
            />
          </Form>
        </Col>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: (
              <Empty
                description={t<string>("organisationProfessional.table.noData")}
              />
            ),
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns(navigate)}
          dataSource={organisationProfessionals.content}
          pagination={{
            total: organisationProfessionals.totalElements,
            pageSize: organisationProfessionals.size,
            current: organisationProfessionals.number + 1,
            onChange: onChangePageNumber,
            onShowSizeChange: onChangePageSize,
            showSizeChanger: true,
          }}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(
                  ROUTES.organisationProfessionals.details.generate(record.id),
                ),
            };
          }}
        />
      </>
    </PageLayout>
  );
};

export default OrganisationProfessionalsScreen;
