import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUnit } from "effector-react";
import { KeywordStore } from "@state/keyword/KeywordStore";
import { requestGetKeywords } from "@state/keyword/KeywordEffects";
import { setKeywords } from "@state/keyword/KeywordEvents";
import { KeywordInfoResponseDTO } from "@state/keyword/dto/keyword.response.dto";
import { toastError } from "@utils/toast-helper";

export const useKeywords = (): KeywordInfoResponseDTO[] => {
  const { t } = useTranslation();
  const keywords = useUnit(KeywordStore);
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(false);

  const fetchKeywords = useCallback(() => {
    requestGetKeywords({}).catch(() => {
      toastError(t<string>("keyword.error.loading"));
    });
  }, [t]);

  useEffect(() => {
    if (!dataHasLoaded && keywords.length === 0) {
      setDataHasLoaded(true);
      fetchKeywords();
    }
  }, [dataHasLoaded, keywords, fetchKeywords]);

  useEffect(() => {
    return requestGetKeywords.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setKeywords(result.data);
      }
    });
  });

  return keywords;
};
