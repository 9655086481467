import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authentifiedStore } from "@state/auth/AuthStore";
import { useUnit } from "effector-react";
import { logout } from "@state/auth/AuthEvents";
import { ROUTES } from "@routes/Routes";

interface HeaderProps {
  className?: string;
}

const Header: FunctionComponent<HeaderProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const authentifiedContext = useUnit(authentifiedStore);

  return (
    <header
      className={`d-flex flex-nowrap align-items-center justify-content-between px-5 py-3 ${className}`}
    >
      <Link to="/">
        <img
          alt={t<string>("header.logo.alt")}
          className="logo px-2"
          src="/logo.svg"
        />
      </Link>
      <div className="user d-flex align-items-center">
        {authentifiedContext && authentifiedContext.user ? (
          <>
            <div className="d-flex flex-column align-items-end justify-content-center px-2">
              <div className="user-name">{`${authentifiedContext.user.firstName} ${authentifiedContext.user.lastName}`}</div>
              <Link
                to={ROUTES.auth.login.generate()}
                className="user-logout"
                onClick={() => logout()}
              >
                {t<string>("login.actions.logout")}
              </Link>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </header>
  );
};

export default Header;
