import i18n from "i18next";
import { DeleteOutlined, EyeOutlined, StopOutlined } from "@ant-design/icons";
import { alignRight, orderAscend, orderDescend } from "@utils/Constant";

import {
  requestDeletePage,
  requestWorkflowPage,
} from "@state/page/PageEffects";

import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import TableButton from "@components/buttons/TableButton";
import { PageStatus } from "@utils/enums/page.status";
import { showConfirm } from "@components/modals/ConfirmModal";
import { CategoryInfoResponseDto } from "@state/category/dto/category.info.response.dto";
import PagesStatusTag from "@views/page/details/PagesStatusTag";
import {
  CustomTooltip,
  tooltipDateTimeRender,
  tooltipRender,
} from "@components/tooltips/CustomTooltip";
import { ColumnsType } from "antd/lib/table/interface";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "@routes/Routes";

const renderActions = (
  record: PageInfoResponseDto,
  navigate: NavigateFunction,
) => (
  <div className="d-inline-flex">
    <TableButton
      icon={<EyeOutlined />}
      tooltipTitle={i18n.t<string>("pages.table.tooltip.view")}
      buttonClassName="btn-primary me-1 btn-list"
      onClick={() => navigate(ROUTES.cms.pages.details.generate(record.id))}
    />
    {record.status === PageStatus.PUBLISHED && (
      <TableButton
        icon={<StopOutlined />}
        buttonClassName="btn-secondary me-1 btn-list"
        onClick={() =>
          showConfirm(
            i18n.t<string>("pages.table.confirmation.unPublish", {
              page: record.title,
            }),
            () => {
              void requestWorkflowPage({
                id: record.id,
                dto: {
                  targetStatus: PageStatus.SAVED,
                },
              });
            },
          )
        }
        tooltipTitle={i18n.t<string>("pages.table.tooltip.unPublish")}
      />
    )}
    {record.status === PageStatus.SAVED && (
      <TableButton
        icon={<DeleteOutlined />}
        buttonClassName="btn-danger btn-list"
        onClick={() =>
          showConfirm(
            i18n.t<string>("pages.table.confirmation.delete", {
              page: record.title,
            }),
            () => {
              void requestDeletePage({
                dto: record,
              });
            },
          )
        }
        tooltipTitle={i18n.t<string>("pages.table.tooltip.delete")}
      />
    )}
  </div>
);

export const columns = (
  categories: CategoryInfoResponseDto[],
  navigate: NavigateFunction,
): ColumnsType<PageInfoResponseDto> => [
  {
    title: i18n.t<string>("pages.table.title"),
    key: "title",
    dataIndex: "title",
    ellipsis: false,
    render: tooltipRender,
    defaultSortOrder: orderAscend,
    sorter: true,
  },
  {
    title: i18n.t<string>("pages.table.version"),
    key: "version",
    dataIndex: "version",
    width: "20%",
  },
  {
    title: i18n.t<string>("pages.table.category"),
    key: "categoryId",
    width: "20%",
    render: (record: PageInfoResponseDto) => {
      const category: CategoryInfoResponseDto | undefined | "" =
        record.categoryId &&
        categories &&
        categories.filter((c) => c.id === record.categoryId)[0];
      return category ? <CustomTooltip data={category.title} /> : "";
    },
  },
  {
    title: i18n.t<string>("pages.table.status"),
    key: "status",
    width: "14%",
    ellipsis: {
      showTitle: false,
    },
    render: (record: PageInfoResponseDto) => {
      return record && <PagesStatusTag status={record.status} />;
    },
  },
  {
    title: i18n.t<string>("pages.table.creationDate"),
    key: "createdAt",
    dataIndex: "createdAt",
    ellipsis: false,
    defaultSortOrder: orderDescend,
    sorter: true,
    render: tooltipDateTimeRender,
  },
  {
    title: i18n.t<string>("common.actions"),
    key: "action",
    fixed: alignRight,
    width: "9%",
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: PageInfoResponseDto) => renderActions(record, navigate),
  },
];
