import React, { ReactNode } from "react";
import TableButton from "@components/buttons/TableButton";
import { alignRight } from "@utils/Constant";
import { EyeOutlined } from "@ant-design/icons/lib";
import i18n from "@utils/i18n/i18n";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "@routes/Routes";
import { OrganisationProfessionalItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.item.response.dto";

const renderActions = (
  record: OrganisationProfessionalItemResponseDto,
  navigate: NavigateFunction,
) => {
  return (
    <div className="d-inline-flex">
      <TableButton
        onClick={() => {
          navigate(
            ROUTES.organisationProfessionals.details.generate(record.id),
          );
        }}
        icon={<EyeOutlined />}
        tooltipTitle={i18n.t<string>(
          "organisationProfessional.table.tooltip.view",
        )}
        buttonClassName="btn-primary me-1 btn-list"
      />
    </div>
  );
};

export const columns = (navigate: NavigateFunction) => [
  {
    title: i18n.t<string>("organisationProfessional.table.id"),
    key: "id",
    dataIndex: "id",
    ellipsis: true,
  },
  {
    title: i18n.t<string>("organisationProfessional.table.opCode"),
    key: "opCode",
    dataIndex: "opCode",
    ellipsis: true,
  },
  {
    title: i18n.t<string>("organisationProfessional.table.action"),
    key: "action",
    fixed: alignRight,
    width: 90,
    onCell: (): {
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    } => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: OrganisationProfessionalItemResponseDto): ReactNode =>
      renderActions(record, navigate),
  },
];
