import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { columns } from "@components/lists/TasksColumns";
import { requestGetTasks } from "@state/tasks/TaskEffects";
import { TaskItemResponseDto } from "@state/tasks/dto/response/task.item.response.dto";
import PageLayout from "@components/layouts/PageLayout";

const TasksScreen: FC = () => {
  const { t } = useTranslation();

  const [taskExecutions, setTaskExecutions] = useState<TaskItemResponseDto[]>(
    [],
  );

  useEffect(() => {
    void requestGetTasks({}).then((result) => {
      if (result.ok && result.data) {
        setTaskExecutions(result.data);
      }
    });
  }, []);

  return (
    <PageLayout title={t<string>("tasks.title")}>
      <>
        <Table
          columns={columns()}
          dataSource={taskExecutions}
          rowKey={(record) => record.taskId}
          rowClassName="cursor-pointer"
        />
      </>
    </PageLayout>
  );
};

export default TasksScreen;
