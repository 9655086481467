import { FC, useCallback, useEffect, useState } from "react";
import { emptySearchResult } from "@utils/Constant";
import { Pageable } from "@type/pagination/pagination.types";
import { UserItemResponseDto } from "@state/users/dto/response/user.item.response.dto";
import { useTranslation } from "react-i18next";
import { Col, Divider, Form, Row, Table } from "antd";
import {
  requestDeleteUser,
  requestGetUsers,
  requestUpdateUserStatus,
} from "@state/users/UserEffects";
import PageLayout from "@components/layouts/PageLayout";
import { SpinIcon } from "@components/icons/SpinIcon";
import { PlusCircleOutlined } from "@ant-design/icons";
import { columns } from "@components/lists/UsersColumns";
import { useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@routes/Routes";
import BasicButton from "@components/buttons/BasicButton";
import InputFormField from "@components/inputs/InputFormField";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { PROFILES } from "@utils/enums/profile.enum";
import { USERS_STATUS } from "@utils/enums/user.status.enum";
import { SorterResult } from "antd/es/table/interface";

const UsersScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);

  const [query, setQuery] = useState<string | undefined>();
  const [selectedProfile, setSelectedProfile] = useState<string | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>();

  const InitialValues = {
    query: query,
  };

  const [users, setUsers] =
    useState<Pageable<UserItemResponseDto>>(emptySearchResult);

  const handleChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "profile",
    order: "ascend",
  });

  const onChangeQuery = (current: string): void => {
    setQuery(current);
    setPageNumber(1);
  };

  const fetchUsers = useCallback(() => {
    setDataHasLoaded(false);
    requestGetUsers({
      page: pageNumber - 1,
      sorter,
      dto: {
        profile: selectedProfile ? [selectedProfile] : undefined,
        status: selectedStatus ? [selectedStatus] : undefined,
        query,
      },
      limit,
    })
      .catch(() => {
        toastError(t<string>("users.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, sorter, limit, t, query, selectedProfile, selectedStatus]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    return requestUpdateUserStatus.done.watch(({ result }) => {
      if (result && result.ok) {
        toastSuccess(t<string>("users.list.messages.change-status-done"));
        fetchUsers();
      }
    });
  });

  useEffect(() => {
    return requestDeleteUser.done.watch(({ result }) => {
      if (result.ok) {
        toastSuccess(t<string>("users.list.messages.delete-success"));
        fetchUsers();
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestGetUsers.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setUsers(result.data);
      }
    });
  });

  return (
    <PageLayout title={t<string>("users.title")}>
      <>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("users.list.title")}
              </h3>
            </Divider>
          </div>
          <div className="w-100 d-flex align-items-center divider-sm-transparent">
            <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
              <BasicButton
                icon={<PlusCircleOutlined />}
                text={t<string>("users.actions.add")}
                className="btn-secondary"
                onClick={() => navigate(ROUTES.admin.users.add.generate())}
              />
            </Divider>
          </div>
        </div>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form initialValues={InitialValues}>
              <InputFormField
                module="users.list.search"
                field="query"
                onPressEnter={(e) => {
                  e.preventDefault();
                  onChangeQuery(e.currentTarget.value);
                }}
              />
            </Form>
          </Col>
          <Col xs={24} lg={6}>
            <SelectFormField
              field="profile"
              module="users.list.search"
              options={PROFILES}
              onSelect={setSelectedProfile}
              allowClear
              onClear={() => {
                setSelectedProfile(undefined);
              }}
            />
          </Col>
          <Col xs={24} lg={6}>
            <SelectFormField
              field="status"
              module="users.list.search"
              options={USERS_STATUS}
              onSelect={setSelectedStatus}
              allowClear
              onClear={() => {
                setSelectedStatus(undefined);
              }}
            />
          </Col>
        </Row>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{ emptyText: dataHasLoaded ? "" : " " }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns()}
          dataSource={users.content}
          pagination={{
            total: users.totalElements,
            pageSize: users.size,
            current: users.number + 1,
            onChange: setPageNumber,
            onShowSizeChange: handleChangeLimit,
          }}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(ROUTES.admin.users.details.generate(record.id)),
            };
          }}
          onChange={(pagination, filters, sorter) =>
            setSorter(sorter as SorterResult<any>)
          }
        />
      </>
    </PageLayout>
  );
};

export default UsersScreen;
