import React from "react";
import { Button, Col, Divider, Popconfirm, Row } from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  QuestionCircleOutlined,
  UpOutlined,
} from "@ant-design/icons/lib";
import { useTranslation } from "react-i18next";
import { useUnit } from "effector-react";
import { Block, ImageContent } from "@type/page/page.types";
import { BlockResponseDto } from "@state/block/dto/block.response.dto";
import { BlockStore } from "@state/block/BlockStore";
import InputFormField from "@components/inputs/InputFormField";
import { PageAddBlocButton } from "@views/page/block/PageAddBlocButton";
import { PageBlockInput } from "@views/page/block/PageBlockInput";
import { ListValue } from "@components/block/ListFormField";
import SelectBackgroundColorFormField from "@components/block/SelectBackgroundColorFormField";

export interface Props {
  editMode?: boolean;
  readOnly?: boolean;
  indexBlock: number;
  block: Block;
  handleAddBlock: (block: Block, index?: number) => void;
  handleUpdateBlock: (block: Block, index: number) => void;
  handleDeleteBlock: (index: number) => void;
  handleAscendBlock?: (index: number) => void;
  handleDescendBlock?: (index: number) => void;
}

export const PageBlock: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();

  const {
    block,
    indexBlock,
    handleUpdateBlock,
    handleDeleteBlock,
    handleAddBlock,
    handleAscendBlock,
    handleDescendBlock,
    editMode,
  } = props;

  const blocks: BlockResponseDto[] | null = useUnit(BlockStore);

  const handleContentChange = (
    content: string | ListValue[] | ImageContent,
    inputIndex?: number,
  ) => {
    if (inputIndex != undefined) {
      block.inputs[inputIndex].content = content;
      handleUpdateBlock(block, indexBlock);
    }
  };

  return (
    <>
      {blocks.length > 0 && (
        <>
          <div
            id={`nbo-block${indexBlock}`}
            className="d-flex flex-column align-items-stretch flex-sm-row nbo-block"
          >
            <div className="w-100 d-flex align-items-center">
              <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
                <h4 className="text-primary mb-0">
                  {t<string>("page.addBlock.title", {
                    index: indexBlock + 1,
                  })}{" "}
                  : {blocks.filter((b) => b.id === block.blockId)[0].label}
                </h4>
              </Divider>
            </div>
            {editMode && (
              <div className="w-100 d-flex align-items-center divider-sm-transparent">
                <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
                  {handleAscendBlock && (
                    <Button
                      className="btn-secondary m-2"
                      onClick={() => handleAscendBlock(indexBlock)}
                    >
                      <UpOutlined /> {t<string>("page.globalButtons.block.up")}
                    </Button>
                  )}
                  {handleDescendBlock && (
                    <Button
                      className="btn-secondary m-2"
                      onClick={() => handleDescendBlock(indexBlock)}
                    >
                      <DownOutlined />{" "}
                      {t<string>("page.globalButtons.block.down")}
                    </Button>
                  )}
                  <PageAddBlocButton
                    buttonLabel={t<string>("page.globalButtons.block.insert")}
                    handleAddBlock={handleAddBlock}
                    index={indexBlock}
                  />
                  <Popconfirm
                    title={t<string>("page.addBlock.cancelConfirmation")}
                    okText={t<string>("common.yes")}
                    cancelText={t<string>("common.no")}
                    onConfirm={() => handleDeleteBlock(indexBlock)}
                    placement="top"
                    icon={<QuestionCircleOutlined />}
                  >
                    <Button htmlType="reset" className="btn-danger m-2">
                      <DeleteOutlined /> {t<string>("page.addBlock.delete")}
                    </Button>
                  </Popconfirm>
                </Divider>
              </div>
            )}
          </div>
          <>
            <Row>
              <Col xs={24} md={12}>
                <InputFormField
                  disabled={!editMode}
                  module={"page.block"}
                  showLabel
                  field="classNames"
                  fieldPrefix={`block${indexBlock}_`}
                  onChange={(e) => {
                    block.classNames = e.target.value;
                    handleUpdateBlock(block, indexBlock);
                  }}
                  initialValue={block.classNames}
                />
              </Col>
              <Col xs={24} md={12}>
                <SelectBackgroundColorFormField
                  className="mx-2"
                  disabled={!editMode}
                  showLabel
                  module="page.block"
                  field="background"
                  fieldPrefix={`block${indexBlock}_`}
                  onChange={(value) => {
                    block.background = value === "default" ? undefined : value;
                    handleUpdateBlock(block, indexBlock);
                  }}
                  initialValue={block.background || "default"}
                />
              </Col>
            </Row>
            {block?.inputs?.map((input, index) => (
              <PageBlockInput
                editMode={editMode}
                key={index}
                input={input}
                indexInput={index}
                indexBlock={indexBlock}
                block={block}
                handleUpdateBlock={handleUpdateBlock}
                handleContentChange={handleContentChange}
              />
            ))}
          </>
        </>
      )}
    </>
  );
};

PageBlock.defaultProps = {
  editMode: true,
};
