/* eslint-disable @typescript-eslint/naming-convention */
import { OptionType } from "@type/form/field.types";

export enum UserStatus {
  INIT = "INIT",
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  STRUCK_OFF = "STRUCK_OFF",
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface UserStatusProperties {
  label: string;
  variant: string;
}

export const userStatusProperties: Record<UserStatus, UserStatusProperties> = {
  [UserStatus.INIT]: {
    label: "users.labels.status.INIT",
    variant: "grey",
  },
  [UserStatus.ACTIVE]: {
    label: "users.labels.status.ACTIVE",
    variant: "success",
  },
  [UserStatus.DISABLED]: {
    label: "users.labels.status.DISABLED",
    variant: "danger",
  },
  [UserStatus.STRUCK_OFF]: {
    label: "users.labels.status.STRUCK_OFF",
    variant: "black",
  },
} as const;

export const USERS_STATUS = Object.keys(UserStatus)
  .map((key) => key as UserStatus)
  .map((key) => ({
    value: key,
    label: userStatusProperties[key].label,
  })) as OptionType[];
