import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Divider, Empty, Form, Row, Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { SpinIcon } from "@components/icons/SpinIcon";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import FileSaver from "file-saver";
import { SaveOutlined } from "@ant-design/icons";
import InputFormField from "@components/inputs/InputFormField";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { OptionType } from "@type/form/field.types";
import { useUnit } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import {
  setFilterPage,
  setTablePageNumber,
  setTablePageSize,
} from "@state/auth/AuthEvents";

import { WorksiteItemsResponseDto } from "@state/worksite/dto/worksite.response.dto";
import { WORKSITE_STATUS } from "@components/worksite/WorksiteStatusTag";
import {
  requestGetWorksiteExportExcel,
  requestGetWorksites,
} from "@state/worksite/WorksiteEffects";
import { columns } from "@components/lists/WorksitesColumns";
import { WORKSITE_RESOURCE_STATUS } from "@components/worksite/WorksiteResourceStatusTag";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@routes/Routes";
import { WorksiteStatus } from "@utils/enums/worksite.status";
import BasicButton from "@components/buttons/BasicButton";
import i18n from "i18next";
import { RowSelectMethod } from "antd/lib/table/interface";
import WorkSiteBonusModal from "@components/modals/WorksiteBonusModal";
import { SorterResult } from "antd/es/table/interface";

interface FilterObject {
  name: string;
  status: string[];
  statusResource: string[];
}

const WorksitesScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authentifiedContext = useUnit(authentifiedStore);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(
    authentifiedContext?.dashboardsPageNumber?.["worksites"] || 1,
  );
  const [pageSize, setPageSize] = useState<number>(
    authentifiedContext?.dashboardsPageSizes?.["worksites"] || 10,
  );

  const [sorter, setSorter] = useState<SorterResult<any>>();

  const [worksites, setWorksites] =
    useState<Pageable<WorksiteItemsResponseDto>>(emptySearchResult);

  const contextFilter: FilterObject | undefined = authentifiedContext
    ?.dashboardsFilter?.["worksites"] as FilterObject | undefined;

  const [name, setName] = useState<string>(contextFilter?.name || "");
  const [statusResource, setStatusResource] = useState<string[]>(
    contextFilter?.statusResource || [],
  );

  const [selectedWorksites, setSelectedWorksites] = useState<
    WorksiteItemsResponseDto[]
  >([]);
  const [status, setStatus] = useState<string[]>(contextFilter?.status || []);

  const InitialValues = {
    statusResource: statusResource,
    query: name,
    status: status,
  };

  const onChangeName = (current: string): void => {
    setName(current);
    setFilterPage({
      value: {
        name: current,
        statusResource: contextFilter?.statusResource,
        status: contextFilter?.status,
      },
      property: "worksites",
    });
    onChangePageNumber(1);
  };

  const onChangeStatusResource = (current: string[]): void => {
    setStatusResource(current);
    setFilterPage({
      value: {
        name: contextFilter?.name,
        statusResource: current,
        status: contextFilter?.status,
      },
      property: "worksites",
    });
    onChangePageNumber(1);
  };

  const onChangeStatus = (current: string[]): void => {
    setStatus(current);
    setFilterPage({
      value: {
        name: contextFilter?.name,
        statusResource: contextFilter?.statusResource,
        status: current,
      },
      property: "worksites",
    });
    onChangePageNumber(1);
  };

  const [statusOptions] = useState<OptionType[]>(
    Object.values(WORKSITE_STATUS),
  );
  const [statusResourceOptions] = useState<OptionType[]>(
    Object.values(WORKSITE_RESOURCE_STATUS),
  );

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [isWorksiteBonusModalVisible, setWorksiteBonusModalVisible] =
    useState<boolean>(false);

  const onChangePageSize = (current: number, newPageSize: number): void => {
    setPageSize(newPageSize);
    setTablePageSize({
      value: newPageSize,
      property: "worksites",
    });
  };

  const onChangePageNumber = (current: number): void => {
    setPageNumber(current);
    setTablePageNumber({
      value: current,
      property: "worksites",
    });
  };
  const fetchPages = useCallback(() => {
    setDataHasLoaded(false);
    requestGetWorksites({
      page: pageNumber - 1,
      limit: pageSize,
      sorter,
      dto: {
        query: name,
        statuses: status,
        statusResource: statusResource,
      },
    })
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, sorter, name, status, statusResource, pageSize, t]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    return requestGetWorksites.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setWorksites(result.data);
      }
    });
  });

  const handleSubmit = () => {
    setButtonLoading(true);
    void requestGetWorksiteExportExcel({
      dto: {
        query: name,
        statuses: status,
        statusResource: statusResource,
      },
    });
  };

  useEffect(() => {
    return requestGetWorksiteExportExcel.done.watch(({ result }) => {
      afterWs(result);
    });
  });

  const afterWs = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(data, result.fileName);
      toastSuccess(t<string>("worksite.messages.generation.success"));
    } else {
      toastError(t<string>("worksite.messages.generation.error"));
    }
  };

  const toArray = (value?: string | null | string[]): string[] => {
    if (!value) {
      return [];
    }
    if (Array.isArray(value)) {
      return value;
    }
    return [value];
  };

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: WorksiteItemsResponseDto[],
      info: { type: RowSelectMethod },
    ) => {
      let checkedSelectedRows = selectedRows;
      if (info.type === "all") {
        const mentionId =
          selectedWorksites?.[0]?.mentionId ??
          checkedSelectedRows?.[0].mentionId;
        checkedSelectedRows = checkedSelectedRows.filter(
          (item) => item.mentionId === mentionId,
        );
      }
      setSelectedWorksites(checkedSelectedRows);
    },
    selectedRowKeys: selectedWorksites.map((item) => item.id),
    getCheckboxProps: (record: WorksiteItemsResponseDto) => ({
      disabled:
        record.worksiteStatus !== WorksiteStatus.ELIGIBLE ||
        (selectedWorksites?.[0] &&
          selectedWorksites[0].mentionId !== record.mentionId),
      name: record.name,
      key: record.id,
    }),
  };

  return (
    <PageLayout title={t<string>("worksite.title")}>
      <>
        <WorkSiteBonusModal
          worksites={selectedWorksites}
          visible={isWorksiteBonusModalVisible}
          onCancel={() => {
            setWorksiteBonusModalVisible(false);
          }}
          downloadInvoice
          onUpdate={fetchPages}
        />
        <Divider orientation="left">
          <h3 className="text-secondary mb-0">{t<string>("page.filter")}</h3>
        </Divider>
        <Form initialValues={InitialValues}>
          <Row>
            <Col xs={24} md={12}>
              <InputFormField
                showLabel
                module="worksite.filters"
                field="query"
                onPressEnter={(e) => {
                  e.preventDefault();
                  onChangeName(e.currentTarget.value);
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <SelectFormField
                mode="multiple"
                allowClear
                showLabel
                module="worksite.filters"
                field="status"
                options={statusOptions}
                onChange={(value) => {
                  onChangeStatus(toArray(value));
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <SelectFormField
                mode="multiple"
                allowClear
                showLabel
                module="worksite.filters"
                field="statusResource"
                options={statusResourceOptions}
                onChange={(value) => {
                  onChangeStatusResource(toArray(value));
                }}
              />
            </Col>
          </Row>
        </Form>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("worksite.list_title")}
              </h3>
            </Divider>
          </div>
          <div className="w-100 d-flex align-items-center divider-sm-transparent">
            <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
              <div className="d-flex gap-2">
                <Tooltip
                  placement="bottomRight"
                  title={i18n.t(
                    "worksite.details.workflow.generateInvoice.disabled-tooltip",
                  )}
                >
                  <div>
                    <BasicButton
                      text={t<string>(
                        "worksite.details.workflow.generateInvoice.label",
                      )}
                      className="btn-primary"
                      onClick={() => {
                        setWorksiteBonusModalVisible(true);
                      }}
                      isLoading={buttonLoading}
                      icon={<SaveOutlined />}
                      disabled={
                        !selectedWorksites || selectedWorksites?.length === 0
                      }
                    />
                  </div>
                </Tooltip>
                <Button
                  className="btn-primary"
                  onClick={handleSubmit}
                  loading={buttonLoading}
                >
                  <SaveOutlined /> {t<string>("common.download")}
                </Button>
              </div>
            </Divider>
          </div>
        </div>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: <Empty description={t<string>("pages.table.noData")} />,
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns(navigate)}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          dataSource={worksites.content}
          pagination={{
            total: worksites.totalElements,
            pageSize: worksites.size,
            current: worksites.number + 1,
            onChange: onChangePageNumber,
            onShowSizeChange: onChangePageSize,
            showSizeChanger: true,
          }}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(ROUTES.worksites.details.generate(record.id)),
            };
          }}
          onChange={(pagination, filters, sorter) =>
            setSorter(sorter as SorterResult<any>)
          }
        />
      </>
    </PageLayout>
  );
};

export default WorksitesScreen;
