import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Empty, Form, Table } from "antd";
import { Loader } from "@components/loader/Loader";
import { OrganisationProfessionalItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.item.response.dto";
import BasicButton from "@components/buttons/BasicButton";
import { DownloadOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/es/table/interface";
import { OrganisationProfessionalGPItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.gaz.professional.item.response.dto";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { OptionType } from "@type/form/field.types";
import DropdownFilter from "@components/filters/DropdownFilter";
import InputFormField from "@components/inputs/InputFormField";
import {
  setFilterPage,
  setTablePageNumber,
  setTablePageSize,
} from "@state/auth/AuthEvents";
import { useUnit } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import {
  PO_FILTERS_RENEWAL_STATE,
  PO_FILTERS_TITLE_PG,
} from "@utils/enums/organisation.professional.filters.enum";
import {
  requestGetOrganisationProfessionalPGs,
  requestGetOrganisationProfessionalPGsExportExcel,
} from "@state/organisationProfessional/OrganisationProfessionalEffets";
import { columns } from "@views/organisationProfessional/details/OrganisationProfessionalPGsColumns";
import { ROUTES } from "@routes/Routes";
import { useNavigate } from "react-router-dom";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import FileSaver from "file-saver";
import { toastError, toastSuccess } from "@utils/toast-helper";

export interface Props {
  organisationProfessional?: OrganisationProfessionalItemResponseDto;
}

const PGsList: React.FunctionComponent<Props> = ({
  organisationProfessional,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useUnit(authentifiedStore);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [subscribers, setSubscribers] =
    useState<Pageable<OrganisationProfessionalGPItemResponseDto>>(
      emptySearchResult,
    );

  const [localQuery, setLocalQuery] = useState<string>("");
  const InitialValues = {
    query: localQuery,
  };

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [selectedRenewalState, setSelectedRenewalState] =
    useState<OptionType>();
  const [selectedTitle, setSelectedTitle] = useState<OptionType>();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(
    authentifiedContext?.dashboardsPageSizes?.[
      "organisationprofessionals-gazprofessionals"
    ] || 10,
  );
  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "code",
    order: "ascend",
  });

  const fetchPgs = useCallback(() => {
    if (organisationProfessional) {
      setDataHasLoaded(false);
      requestGetOrganisationProfessionalPGs({
        page: pageNumber - 1,
        limit: pageSize,
        sorter,
        dto: {
          query: localQuery,
          renewalState: selectedRenewalState?.value,
          title: selectedTitle?.value,
        },
        id: organisationProfessional.id,
      }).catch(() => {
        toastError(t<string>("organisationProfessional.details.listPGs.error"));
      });
    }
  }, [
    organisationProfessional,
    pageNumber,
    pageSize,
    t,
    sorter,
    localQuery,
    selectedRenewalState,
    selectedTitle,
  ]);

  useEffect(() => {
    fetchPgs();
  }, [fetchPgs]);

  useEffect(() => {
    return requestGetOrganisationProfessionalPGs.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setSubscribers(data);
          setDataHasLoaded(true);
        } else {
          toastError(
            t<string>("organisationProfessional.details.listPGs.error"),
          );
        }
      },
    );
  });

  const handleSubmit = () => {
    if (organisationProfessional) {
      setButtonLoading(true);
      void requestGetOrganisationProfessionalPGsExportExcel(
        organisationProfessional.id,
      );
    }
  };

  useEffect(() => {
    return requestGetOrganisationProfessionalPGsExportExcel.done.watch(
      ({ result }) => {
        afterExport(result);
      },
    );
  });

  const afterExport = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(data, result.fileName);
      toastSuccess(
        t<string>("organisationProfessional.details.listPGs.export.success"),
      );
    } else {
      toastError(
        t<string>("organisationProfessional.details.listPGs.export.error"),
      );
    }
  };

  const onChangePageSize = (current: number, newPageSize: number): void => {
    setPageSize(newPageSize);
    setTablePageSize({
      value: newPageSize,
      property: "organisationprofessionals-gazprofessionals",
    });
  };

  const onChangePageNumber = (current: number): void => {
    setPageNumber(current);
    setTablePageNumber({
      value: current,
      property: "organisationprofessionals-gazprofessionals",
    });
  };

  const onChangeQuery = (current: string): void => {
    setLocalQuery(current);
    setFilterPage({
      value: {
        query: current,
      },
      property: "organisationprofessionals-gazprofessionals",
    });
    onChangePageNumber(1);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end flex-wrap my-3 gap-3">
        <h3 className="flex-grow-1 text-primary mb-0">
          {t("organisationProfessional.details.listPGs.pgSubscriber", {
            count: subscribers.totalElements,
          })}
        </h3>
        <BasicButton
          variant="transparent-blue"
          text={t<string>(
            "organisationProfessional.details.listPGs.downloadCSV",
          )}
          icon={<DownloadOutlined />}
          iconRight
          className="download-csv-button"
          onClick={handleSubmit}
          isLoading={buttonLoading}
        />
      </div>
      <div className="d-flex align-items-center justify-content-end flex-wrap mb-3 gap-3">
        <div className="d-flex align-items-center justify-content-end flex-wrap gap-3">
          <DropdownFilter
            label={t<string>(
              "organisationProfessional.details.listPGs.filters.renewalState.label",
            )}
            items={PO_FILTERS_RENEWAL_STATE}
            selectedItem={selectedRenewalState}
            setSelectedItem={setSelectedRenewalState}
          />
          <DropdownFilter
            label={t<string>(
              "organisationProfessional.details.listPGs.filters.title.label",
            )}
            items={PO_FILTERS_TITLE_PG}
            selectedItem={selectedTitle}
            setSelectedItem={setSelectedTitle}
          />
          <Form initialValues={InitialValues}>
            <InputFormField
              module="organisationProfessional.details.listPGs.search"
              field="query"
              onPressEnter={(e) => {
                e.preventDefault();
                onChangeQuery(e.currentTarget.value);
              }}
            />
          </Form>
        </div>
      </div>
      <Table
        style={{ whiteSpace: "break-spaces" }}
        locale={{
          emptyText: <Empty description={t<string>("common.noData")} />,
        }}
        columns={columns}
        dataSource={subscribers.content}
        loading={{
          spinning: !dataHasLoaded,
          indicator: <Loader />,
        }}
        size="small"
        rowKey={(record: OrganisationProfessionalGPItemResponseDto) =>
          record?.id
        }
        className="app-contact-pro-table pro-table-large-font"
        pagination={{
          total: subscribers.totalElements,
          pageSize: subscribers.size,
          current: subscribers.number + 1,
          onChange: setPageNumber,
          onShowSizeChange: onChangePageSize,
          showSizeChanger: true,
        }}
        onChange={(pagination, filters, sorter) =>
          setSorter(sorter as SorterResult<any>)
        }
        onRow={(record) => {
          return {
            onClick: () =>
              navigate(ROUTES.gazProfessionals.details.generate(record.id)),
          };
        }}
      />
    </>
  );
};

export default PGsList;
